import React from 'react'
import { Welcome } from '../components/welcome'
import { Vidbudova } from '../components/vidbudova'
import { Help } from '../components/help'
import { Marquees } from '../components/marquees'
import { Statistics } from '../components/statistics'
import { Donate } from '../components/donate'
import { Contact } from '../components/contact'
import { Thanks } from '../components/thanks'
import styled from 'styled-components'
import { Problem } from '../components/problem'
import { Donates } from '../components/donates'

export const HomePage = ({}) => {
  return (
    <>
      <Welcome />
      <Problem />
      <Vidbudova />
      <Marquees />
      <Help />
      <Donate />
      <Contact />
      <Thanks />
    </>
  )
}