import { observer } from 'mobx-react-lite'
import React from 'react'
import styled from 'styled-components'
import IntlStore from '../../store/Intl.store'
import { t } from '../../utils/Intl'

export const DonateButton = observer(({ amount, sub=false, readOnly=true, current }) => {

  let currency = IntlStore.lang === 'ua' ? 'UAH' : IntlStore.lang === 'de' ? 'EUR' : 'USD'

  const createOrder = (amount) => {
    // var button = window.$ipsp.get('button')
    // button.setMerchantId(1502938)
    // button.setAmount(amount, currency)
    // button.setResponseUrl('https://vidbudovaua.com/')
    // button.setHost('pay.fondy.eu')
    // sub && button.setRecurringState(true)
    // sub && button.addRecurringData({
    //   amount,
    //   period: 'month',
    //   every: 1
    // })
    // return button.getUrl()
  }

  const handleCreateOrder = async () => {
    try {
      console.log('handleCreateOrder')
      const response = await fetch('https://www.liqpay.ua/api/3/checkout', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'ACCESS-CONTROL-ALLOW-ORIGIN': '*'
        },
        body: JSON.stringify({
          'public_key': 'sandbox_i1234567890',
          'amount': amount,
          'currency': currency,
          'description': 'Donation',
          'order_id': 'order_id_1',
          'version': 3,
          'server_url': 'https://vidbudovaua.com/',
          'result_url': 'https://vidbudovaua.com/',
          'sandbox': 1
        })
      })
      const data = await response.json()
      return console.log(data)
    } catch (error) {
      return console.error(error
      )
    }
  }

  const price = IntlStore.lang === 'ua' ? '₴' : IntlStore.lang === 'de' ? '€' : '$' 

  return (
    <Container cur={current}>
      <AmountContainer 
        readOnly={readOnly} 
        value={amount} 
        placeholder={t('donate', 'other')}
        type='number'
        cur={current}
      />
      <Price cur={current}>{price}</Price>
    </Container>
  )
})


const Price = styled.span`
  position: absolute;
  right: -5px;
  /* transform: translateY(50%); */
  top: -5px;
  height: 40px;
  width: 40px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ cur }) => cur ? 'var(--secondary)' : 'var(--primary)'};
  font-size: 18px;
  font-weight: 600;
`

const AmountContainer = styled.input`
  width: 100%;
  height: 100%;
  background: none;
  border: none;
  outline: none;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  color: ${({ cur }) => cur ? '#FFF' : '#272727'};
  cursor: pointer;
  ::-webkit-outer-spin-button, ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  };
  input[type=number] {
    -moz-appearance: textfield;
  }
`

const Container = styled.a`
  position: relative;
  width: 100%;
  height: 50px;
  border: none;
  outline: none;
  background-color: ${({ cur }) => cur ? 'var(--primary)' : '#FFF'};
  border-radius: 4px;
  /* border-bottom: 2px solid #EFEFEF; */
  cursor: pointer;
  font-size: 18px;
  color: #272727;
  transition: all 300ms ease-out;
  ::placeholder {
    color: #272727;
  };
  :hover {
    background-color: var(--primary);
    transition: all 300ms ease-out;
    ${AmountContainer} {
      color: #FFF;
    };
    ${Price} {
      color: var(--secondary);
    }
  };
`