import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Container, Content, ContentDescription, ContentHeader, ContentHeaderLeft, ContentHeaderSticker, ContentInner, ContentTitle, Wrapper } from '../../utils/styles'
import { observer } from 'mobx-react-lite'
import { Table } from './Table'
import { t } from '../../utils/Intl'
import sticker from '../../assets/stickers/sticker_angry.webp'

export const Statistics = observer(({ }) => {

  return (
    <Container>
      <Content>
        <ContentHeader id='stat'>
          <ContentHeaderLeft>
            <ContentTitle>{t('statistics', 'title')}</ContentTitle>
            <ContentDescription>{t('statistics', 'descrtiption')}</ContentDescription>
          </ContentHeaderLeft>
          <ContentHeaderSticker src={sticker} />
        </ContentHeader>
        <ContentInner>
          <Table />
        </ContentInner>
      </Content>
    </Container>
  )
})
