import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import ua from '../assets/flags/ukraine.svg'
import en from '../assets/flags/united.svg'
import de from '../assets/flags/germany.svg'

import IntlStore from '../store/Intl.store'
import { observer } from 'mobx-react-lite'

export const Language = observer(({ }) => {

  const getLang = () => {
    let img
    let lang
    switch (IntlStore.lang) {
      case 'uk' || 'ru':
        img = ua
        lang = 'Українська'
        break;
      case 'en':
        img = en
        lang = 'English'
        break;
      case 'de':
        img = de
        lang = 'Deutsch'
        break;
      default:
        img = ua
        lang = 'Українська'
        break;
    }
    return {img, lang}
  }

  const initialSelectedLang = {
    img: getLang().img,
    lang: getLang().lang
  }

  const [selectedLang, setLang] = useState(initialSelectedLang)
  const [openLangList, setOpenLangList] = useState(false)

  const handleChangeLang = (lang) => {
    IntlStore.changeLang(lang)
    switch (lang) {
      case 'uk' || 'ru':
        setLang({img: ua, lang: 'Українська'})
        break;
      case 'en':
        setLang({img: en, lang: 'English'})
        break;
      case 'de':
        setLang({img: de, lang: 'Deutsch'})
        break;
      default:
        setLang({img: ua, lang: 'Українська'})
        break;
    }
    setOpenLangList(false)
  }

  const handleOpenList = useCallback(() => {
    setOpenLangList(old => !old)
  }, [])

  return (
    <Container>
      <Select>
        <Selected onClick={handleOpenList}><img src={selectedLang.img} /> <span>{selectedLang.lang}</span></Selected>
        {openLangList &&
          <LangList>
            <li onClick={() => handleChangeLang('ua')}><img src={ua} /> <span>Українська</span> </li>
            <li onClick={() => handleChangeLang('en')}><img src={en} /> <span>English</span> </li>
            <li onClick={() => handleChangeLang('de')}><img src={de} /> <span>Deutsch</span> </li>
          </LangList>
        }
      </Select>
    </Container>
  )
})

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-left: 40px; */
`

const Select = styled.div`

`
const Selected = styled.div`
  display: flex;
  align-items: center;
  img {
    width: 24px;
  };
  span {
    color: #FFF;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    margin-left: 10px;
    @media (max-width: 768px) {
      color: #272727;
    }
  }
  cursor: pointer;
`

const LangList = styled.ul`
  display: flex;
  z-index: 10;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  margin-top: 20px;
  padding-inline-start: 0px;
  background-color: #FFF;
  box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
  border-radius: 4px;
  margin-left: -10px;
  padding: 10px 0;
  li {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    padding: 5px 10px;
    cursor: pointer;
    :hover > span {
      color: #FFD700;
      transition: .5s;
    };
    img {
      width: 24px;
    };
    span {
      color: #272727;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 1px;
      margin-left: 10px;
      transition: .5s;
    }
  }
`
