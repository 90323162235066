import { makeAutoObservable, runInAction } from "mobx"

class Intl {
  lang = localStorage.getItem('lang') || window.navigator.language.substring(0, 2)

  constructor() {
    makeAutoObservable(this)
  }

  changeLang = (_lang) => {
    runInAction(() => {
      this.lang = _lang
      localStorage.setItem('lang', _lang)
    })
    console.log(this.lang)
  }
}

export default new Intl()
