import React from 'react'
import styled from 'styled-components'
import Marquee from 'react-fast-marquee'
import { Button } from '../ui/Button'
import sticker from '../../assets/stickers/sticker_who.webp'
import { observer } from 'mobx-react-lite'
import { t } from '../../utils/Intl'
import { Container, Content, ContentHeader, ContentHeaderLeft, ContentInner, ContentTitle } from '../../utils/styles'

export const Help = observer(({ }) => {

  return (
    <Container>
      <Content justify='center'>
        <ContentHeader id='help'>
          <ContentHeaderLeft>
            <ContentTitle>{t('help', 'title')}</ContentTitle>
          </ContentHeaderLeft>
        </ContentHeader>
        <AnswerWrapper>
          <Answer>
            <span>{t('help', 'description')}</span>
            <Buttons>
              <Button label={t('buttons', 'donate')} link='#donate' />
            </Buttons>
          </Answer>
          <Sticker src={sticker} />
        </AnswerWrapper>
      </Content>
    </Container>
  )
})


const AnswerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap-reverse;
`

const Answer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff;
  max-width: 617px;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 20px;
  span {
    font-style: italic;
    font-weight: 600;
    font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1280 - 320)));
  };
`

const Buttons = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  grid-column-gap: 40px;
  grid-row-gap: 20px;
`

const Sticker = styled.img`
  width: calc(270px + (400 - 270) * ((100vw - 320px) / (1280 - 320)));
`
