import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { Button } from '../ui/Button'
import { Input } from '../ui/Input'
import { ContactsService } from '../../services/Contacts.service'
import { t } from '../../utils/Intl'


export const Form = observer(() => {
  
  const [name, setName] = useState('')
  const [nameError, setNameError] = useState('')
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState('')
  const [phone, setPhone] = useState('')
  const [phoneError, setPhoneError] = useState('')
  const [message, setMessage] = useState('')
  const [messageError, setMessageError] = useState('')
  const [statusMessage, setStatusMessage] = useState('')

  const sendForm = async () => {
    if(!formValidation()) return false
    let response = await ContactsService.createContact(name, email, phone, message)
    if(response.status === 201) {
      console.log('Has been created')
      setStatusMessage('Ваше повідомлення було відправлене.')
      setTimeout(() => {
        window.location.reload()
      }, 1000);
    }
  }

  useEffect(() => {
    setNameError('')
  }, [name])

  useEffect(() => {
    setEmailError('')
  }, [email])

  useEffect(() => {
    setPhoneError('')
  }, [phone])

  useEffect(() => {
    setMessageError('')
  }, [message])

  const formValidation = () => {
    let status = true
    if(name === '') {
      setNameError(t('form_error', 'default'))
      status = false
    }
    if(email === '') {
      setEmailError(t('form_error', 'default'))
      status = false
    }
    if (!email.match("^([a-z0-9_-]+\\.)*[a-z0-9_-]+@[a-z0-9_-]+(\\.[a-z0-9_-]+)*\\.[a-z]{2,6}$")) {
      setEmailError(t('form_error', 'email'))
      status = false
    }
    if (phone === '') {
      setPhoneError(t('form_error', 'default'))
      status = false
    }
    if (!phone.match("^\\+\\d+$")) {
      setPhoneError(t('form_error', 'phone'))
      status = false
    }
    if (message === '') {
      setMessageError(t('form_error', 'default'))
      status = false
    }
    if (message.length > 500) {
      setMessageError(t('form_error', 'message'))
      status = false
    }
    return status
  }
  
  return (
    <Container>
      <Input label={t('contact_form', 'name')} error={nameError} value={name} onChangeText={setName} />
      <Input label={t('contact_form', 'email')} type='email' inputMode='email' value={email} error={emailError} onChangeText={setEmail} />
      <Input label={t('contact_form', 'phone')} description={t('contact_form', 'phone_description')} type='tel' inputMode='tel' error={phoneError} value={phone} onChangeText={setPhone} />
      <Input area label={t('contact_form', 'message')} error={messageError} value={message} onChangeText={setMessage} />
      <SubmitButton onClick={async () => await sendForm()}>{t('buttons', 'send')}</SubmitButton>
      {statusMessage !== '' && <StyledMessage>{statusMessage}</StyledMessage>}
    </Container>
  )
})

const Container = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
  @media (max-width: 1280px) {
    width: calc(100% + (50 - 100) * ((100vw - 320px) / (1280 - 320)));
  }
`
const StyledMessage = styled.span`
  margin-top: 20px;
  align-self: center;
  text-align: center;
  color: #48d148;
  font-weight: 600;
`
const SubmitButton = styled.span`
  width: 100%;
  height: 50px;
  background-color: #0A5CB1;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 18px;
  color: #fff;
  margin-top: 10px;
  cursor: pointer;
  transition: .5s;
  :hover {
    box-shadow: 0px 4px 10px rgba(10, 92, 177, 0.5);
    transition: .5s;
  }
`