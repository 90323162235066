import React from 'react'
import styled from 'styled-components'
import Marquee from 'react-fast-marquee'
import { observer } from 'mobx-react-lite'
import { t } from '../../utils/Intl'

export const Marquees = observer(({  }) => {

  const Container = styled.div`
    width: 100%;
    white-space: nowrap;
  `

  return (
    <Container
    >
      <Marquee 
      gradient={false}
      style={{
        background: '#0057B8'
      }}>
        <Line text={t('marquees', 'line_1')} ecolor='var(--secondary)'/>
      </Marquee>
      <Marquee
        gradient={false}
        style={{
          background: 'var(--secondary)'
        }}>
        <Line text={t('marquees', 'line_2')} tcolor='#272727' />
      </Marquee>
    </Container>
  )
})


const Line = observer(({ text, tcolor='#FFF', ecolor='#FFF' }) => {
  
  const Container = styled.div`
    width: 100%;
    height: calc(40px + (70 - 40) * ((100vw - 320px) / (1280 - 320)));
    display: flex;
    flex-direction: row;
    align-items: center;
  `
  const Element = styled.div`
    margin-right: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
  `
  const Text = styled.span`
    color: ${tcolor};
    font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1280 - 320)));
    font-weight: 600;
  `
  const Circle = styled.div`
    width: calc(10px + (15 - 10) * ((100vw - 320px) / (1280 - 320)));
    height: calc(10px + (15 - 10) * ((100vw - 320px) / (1280 - 320)));
    border-radius: 50%;
    background-color: ${ecolor};
    margin-left: 20px;
  `

  return (
    <Container>
      <Element>
        <Text>{text}</Text>
        <Circle />
      </Element>
      <Element>
        <Text>{text}</Text>
        <Circle />
      </Element>
      <Element>
        <Text>{text}</Text>
        <Circle />
      </Element>
      <Element>
        <Text>{text}</Text>
        <Circle />
      </Element>
      <Element>
        <Text>{text}</Text>
        <Circle />
      </Element>
      <Element>
        <Text>{text}</Text>
        <Circle />
      </Element>
      <Element>
        <Text>{text}</Text>
        <Circle />
      </Element>
    </Container>
  )
})