import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Container, Content, ContentDescription, ContentTitle, Wrapper } from '../../utils/styles'
import sticker from '../../assets/stickers/stickerthanks.webp'
import { Button } from '../ui/Button'
import { t } from '../../utils/Intl'
import { observer } from 'mobx-react-lite'


export const Thanks = observer(({ }) => {

  return (
    <Container>
      <Content direction='column' align='center'>
        <Title>{t('thanks', 'title')}</Title>
        <ContentDescription>{t('thanks', 'description')}</ContentDescription>
        <Sticker src={sticker} />
        <Button label={t('buttons', 'donate')} link='#donate' />
      </Content>
    </Container>
  )
})

const Title = styled.span`
  font-weight: 700;
  text-align: center;
  color: #272727;
  margin-top: 100px;
  font-size: calc(24px + (36 - 24) * ((100vw - 320px) / (1280 - 320)));
`

const Description = styled.span`
  font-weight: 400;
  text-align: center;
  color: #272727;
  margin-top: 80px;
  font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1280 - 320)));
  @media (max-width: 1280px) {
    margin-top: calc(40px + (80 - 40) * ((100vw - 320px) / (1280 - 320)));
  };
`

const Sticker = styled.img`
  width: 200px;
  margin: 40px 0;
`