import { observer } from 'mobx-react-lite'
import React from 'react'
import styled from 'styled-components'

export const Button = observer(({ label, border, link, color='#FFF', target}) => {
  
  const Container = styled.a`
    background: ${!border ? 'var(--primary)' : 'transparent'};
    padding: 16px 26px;
    border-radius: 4px;
    border: ${border ? '4px solid var(--secondary)' : 'none'};
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: .3s;
    :hover {
      box-shadow: ${border ? '0px 4px 10px rgba(255, 215, 0, 0.5)' : '0px 4px 10px rgba(10, 92, 177, 0.5)' };
      transition: .3s;
    }
  `
  const Label = styled.div`
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    color: ${color};
  `

  return (
    <Container href={link} target={target ? '_blank' : '_self'}>
      <Label>{label}</Label>
    </Container>
  )
})

