import { observer } from 'mobx-react-lite'
import React from 'react'
import styled from 'styled-components'
import { t } from '../../utils/Intl'
import { Container, Content, ContentHeader, ContentHeaderLeft, ContentInner, ContentTitle, Wrapper } from '../../utils/styles'
import { PaymentCard } from './PaymentCard'
import { Requisites } from './Requisites'
import qrcode from '../../assets/qrcode.jpg'

export const Donate = observer(({ }) => {

  return (
    <Container>
      <Content>
        <ContentHeader id='donate'>
          <ContentHeaderLeft>
            <ContentTitle>{t('donate', 'title')}</ContentTitle>
          </ContentHeaderLeft>
        </ContentHeader>
        <ContentInner align='flex-start'>
          <PaymentCard />
          <Requisites />
        </ContentInner>
      </Content>
    </Container>
  )
})