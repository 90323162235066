import { api } from ".";

export class ResourceService {

  static async create(name, email, phone, other_contacts, region, city, resource, delivery, message) {
    const query = await api.post(`resources/`,
      {
        name, 
        email, 
        phone, 
        other_contacts, 
        region, 
        city, 
        resource, 
        delivery, 
        message
      }
    )
    console.log(query)
    return query
  }
}