import { api } from ".";

export class ContactsService {

  static async createContact(name, email, phone, message) {
    const query = await api.post(`feedbacks/`,
      {
        name,
        email,
        phone,
        message
      }
    )
    console.log(query)
    return query
  }
}