import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import video from '../../assets/prnt.mp4'
import { Container, Content } from '../../utils/styles'
import { Header } from '../Header'
import { Button } from '../ui/Button'
import { t } from '../../utils/Intl'
import { observer } from 'mobx-react-lite'

export const Welcome = observer(({ }) => {

  return (
    <Container isVh>
      <Media>
        <Video src={video} autoPlay playsInline muted loop></Video>
      </Media>
      <Content direction='column' align='center' zIndex={10}>
        <Header />
        <Title>{t('welcome', 'title')}</Title>
        <Description>{t('welcome', 'description')}</Description>
        <Buttons>
          <Button label={t('buttons', 'donate')} link='#donate' />
        </Buttons>
      </Content>
    </Container>
  )
})

const Media = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #FFF;
  ::before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    content: '';
    background-color: rgba(0, 0, 0, .5);
  }
`

const Video = styled.video`
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
  transform: translate(-50%, -50%);
`

// Content

const Title = styled.span`
  font-weight: 700;
  max-width: 1050px;
  text-align: center;
  color: #FFF;
  font-size: calc(32px + (42 - 32) * ((100vw - 320px) / (1280 - 320)));
  margin-top: calc(40px + (100 - 40) * ((100vw - 320px) / (1280 - 320)));
`

const Description = styled.span`
  font-weight: 500;
  text-align: center;
  max-width: 500px;
  color: #FFF;
  margin-top: 80px;
  font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1280 - 320)));
  @media (max-width: 1280px) {
    margin-top: calc(40px + (80 - 40) * ((100vw - 320px) / (1280 - 320)));
  };
`

const Buttons = styled.div`
  margin-top: 80px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  grid-column-gap: 40px;
  grid-row-gap: 20px;
  max-width: max-content;
  @media (max-width: 1280px) {
    margin-top: calc(40px + (80 - 40) * ((100vw - 320px) / (1280 - 320)));
  };
`