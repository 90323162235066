import styled, { createGlobalStyle } from 'styled-components'

export const Global = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Raleway', sans-serif;
    list-style: none;
    text-decoration: none;
  }
  html{ 
    background: #FFF;
  }
  body {
    background: rgb(0,87,184);
    background: linear-gradient(180deg, rgba(0,87,184,.02), rgba(151,92,92,.02) 50%, rgba(255,215,0,.02) 100%);
  }
  :root {
    --primary: #0A5CB1;
    --secondary: #FFD700;
    --text: #272727;
  }
  html, body, #root, .App {
    height: 100%;
    scroll-behavior: smooth;
  }
`


export const Container = styled.div`
  width: 100%;
  height: ${props => props.isVh && '100vh'};
  /* border: 1px dotted #FF0000; */
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ bcolor }) => bcolor};
`

export const Content = styled.div`
  width: 1280px;
  height: ${props => props.isVh && '100vh'};
  padding: 40px 0px;
  display: flex;
  flex-direction: ${({ direction }) => direction || 'row'};
  align-items: ${({ align }) => align || 'flex-start'};
  justify-content: ${({ justify }) => justify || 'flex-start'};
  flex-wrap: ${({ flexWrap }) => flexWrap || 'wrap'};
  /* border: 1px dotted green; */
  z-index: ${props => props.zIndex};
  @media (max-width: 1280px) {
    width: calc(270px + (1280 - 270) * ((100vw - 320px) / (1280 - 320)));
  }
`

export const ContentHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (max-width: 768px) {
    justify-content: center;
  }
`

export const ContentHeaderLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const ContentHeaderSticker = styled.img`
  width: 260px;
`


export const ContentTitle = styled.span`
  font-weight: 700;
  font-size: calc(36px + (48 - 36) * ((100vw - 320px) / (1280 - 320)));
  color: var(--text);
`

export const ContentDescription = styled.p`
  max-width: 720px;
  color: #b0b0b0;
  font-weight: 600;
  font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1280 - 320)));
  margin-top: calc(10px + (15 - 10) * ((100vw - 320px) / (1280 - 320)));
`

export const ContentInner = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => direction || 'row'};
  align-items: ${({ align }) => align || 'center'};
  justify-content: ${({ justify }) => justify || 'center'};
  flex-wrap: ${({ flexWrap }) => flexWrap || 'wrap'};
  width: 100%;
  border-top: ${({ border }) => border && '1px solid #EFEFEF'};
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  margin-top: 40px;
  padding-top: 40px;
  @media (max-width: 1280px) {
    flex-direction: column;
  }
`

export const ContentInnerTitle = styled.span`
  font-size: calc(24px + (32 - 24) * ((100vw - 320px) / (1280 - 320)));
  font-weight: 700;
  color: var(--text);
  margin: 20px 0;
`