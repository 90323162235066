import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { t } from '../../utils/Intl'
import styled from 'styled-components'
import { DonateButton } from './DonateButton'
import IntlStore from '../../store/Intl.store'

export const DonateMenu = observer(({ }) => {
  const [currentTab, setCurrentTab] = useState(1)

  return (
    <Wrapper>
      <Tabs>
        <TabsNav>
          <TabsItem onClick={() => setCurrentTab(0)} currentTab={currentTab === 0 && true}>{t('donate', 'one')}</TabsItem>
          <TabsItem onClick={() => setCurrentTab(1)} currentTab={currentTab === 1 && true}>{t('donate', 'sub')}</TabsItem>
        </TabsNav>
        <TabsBody>
          {
            currentTab === 0
            ?
            <TabsBlock>
              <Container>
                <ContainerButtons>
                  <DonateButton amount={IntlStore.lang === 'ua' ? 100 : IntlStore.lang === 'de' ? 10 : 10} />
                  <DonateButton current amount={IntlStore.lang === 'ua' ? 250 : IntlStore.lang === 'de' ? 50 : 50} />
                  <DonateButton amount={IntlStore.lang === 'ua' ? 1000 : IntlStore.lang === 'de' ? 100 : 100} />
                  <DonateButton />
                </ContainerButtons>
              </Container>
            </TabsBlock>
            :
            <TabsBlock>
              <Container>
                <ContainerButtons>
                  <DonateButton amount={IntlStore.lang === 'ua' ? 50 : IntlStore.lang === 'de' ? 7 : 7} sub={true} />
                  <DonateButton current amount={IntlStore.lang === 'ua' ? 150 : IntlStore.lang === 'de' ? 35 : 35} sub={true} />
                  <DonateButton amount={IntlStore.lang === 'ua' ? 500 : IntlStore.lang === 'de' ? 50 : 50} sub={true} />
                  <DonateButton sub={true} />
                </ContainerButtons>
              </Container>
            </TabsBlock>
          }
        </TabsBody>
      </Tabs>
    </Wrapper>
  )
})

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const Container = styled.div`
  margin-bottom: 20px;
`

const ContainerButtons = styled.div`
  margin-top: 10px;
  width: 100%;
  /* display: flex;
  flex-direction: row;
   */
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 10px;
  grid-column-gap: 10px;
`

const Tabs = styled.div`
  margin-top: 20px;
`

const TabsNav = styled.nav`
  display: flex;
  grid-row-gap: 20px;
`

const TabsItem = styled.button`
  flex: 1;
  height: 50px;
  /* box-shadow: 0 4px 10px rgba(0, 0, 0, .05); */
  background: none;
  /* background-color: ${({ currentTab }) => currentTab ? 'var(--primary)' : '#F5F5F5'}; */
  font-size: calc(14px + (17 - 14) * ((100vw - 320px) / (1280 - 320)));
  font-weight: 700;
  border: none;
  border-radius: 4px;
  border: ${({ currentTab }) => currentTab ? '1px solid var(--primary)' : '1px solid transparent'};
  display: flex;
  justify-content: center;
  align-items: center;
  /* color: ${({ currentTab }) => currentTab ? '#FFF' : 'var(--primary)'}; */
  color: #272727;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  transition: .4s;
`

const TabsBody = styled.div`
  width: 100%;
  margin-top: 20px;
  border-radius: 12px;
`

const TabsBlock = styled.div`
  display: flex;
  flex-direction: column;
`