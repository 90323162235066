import React, { useState, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { Input } from '../components/ui/Input';
import { Container, Content } from '../utils/styles'
import Select from 'react-select'
import { observer } from 'mobx-react-lite';
import { Radioded } from '../components/ui/Radioded';
import { ResourceService } from '../services/Resource.service';
import { t } from '../utils/Intl';

export const HavePage = observer(({ }) => {
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  
  const [phone, setPhone] = useState('');
  const [phoneError, setPhoneError] = useState('');
  
  const [otherContacts, setOtherContacts] = useState('');
  const [otherContactsError, setOtherContactsError] = useState('');
  
  const [region, setRegion] = useState({
    value: '',
    label: ''
  });
  const [regionError, setRegionError] = useState('');
  
  const [delivery, setDelivery] = useState('')
  const [deliveryError, setDeliveryError] = useState('');
  
  const [city, setCity] = useState('')
  const [cityError, setCityError] = useState('');
  
  const [resource, setResource] = useState('')
  const [resourceError, setResourceError] = useState('');
  
  const [message, setMessage] = useState('')
  const [messageError, setMessageError] = useState('');
  
  const [otherCountry, setOtherCountry] = useState(false)
  const [otherCountryInput, setOtherCountryInput] = useState('')
  const [otherCountryInputError, setOtherCountryInputError] = useState('')
  
  const [statusMessage, setStatusMessage] = useState('')

  useEffect(() => {
    region.value === 'other' ? setOtherCountry(true) : setOtherCountry(false)
  }, [region])

  const options = [
    { value: 'other', label: t('form_regions', 'other') },
    { value: 'UA-05', label: t('form_regions', 'UA-05') },
    { value: 'UA-07', label: t('form_regions', 'UA-07') },
    { value: 'UA-09', label: t('form_regions', 'UA-09') },
    { value: 'UA-12', label: t('form_regions', 'UA-12') },
    { value: 'UA-14', label: t('form_regions', 'UA-14') },
    { value: 'UA-18', label: t('form_regions', 'UA-18') },
    { value: 'UA-56', label: t('form_regions', 'UA-56') },
    { value: 'UA-21', label: t('form_regions', 'UA-21') },
    { value: 'UA-23', label: t('form_regions', 'UA-23') },
    { value: 'UA-26', label: t('form_regions', 'UA-26') },
    { value: 'UA-30', label: t('form_regions', 'UA-30') },
    { value: 'UA-32', label: t('form_regions', 'UA-32') },
    { value: 'UA-35', label: t('form_regions', 'UA-35') },
    { value: 'UA-40', label: t('form_regions', 'UA-40') },
    { value: 'UA-46', label: t('form_regions', 'UA-46') },
    { value: 'UA-48', label: t('form_regions', 'UA-48') },
    { value: 'UA-51', label: t('form_regions', 'UA-51') },
    { value: 'UA-53', label: t('form_regions', 'UA-53') },
    { value: 'UA-59', label: t('form_regions', 'UA-59') },
    { value: 'UA-61', label: t('form_regions', 'UA-61') },
    { value: 'UA-63', label: t('form_regions', 'UA-63') },
    { value: 'UA-65', label: t('form_regions', 'UA-65') },
    { value: 'UA-68', label: t('form_regions', 'UA-68') },
    { value: 'UA-71', label: t('form_regions', 'UA-71') },
    { value: 'UA-74', label: t('form_regions', 'UA-74') },
    { value: 'UA-77', label: t('form_regions', 'UA-77') }
  ]

  const deliveryOptions = [
    {
      name: "delivery",
      value: "city",
      label: t('form_delivery', 'city')
    },
    {
      name: "delivery",
      value: "region",
      label: t('form_delivery', 'region')
    },
    {
      name: "delivery",
      value: "country",
      label: t('form_delivery', 'country')
    },
    {
      name: "delivery",
      value: "cant",
      label: t('form_delivery', 'cant')
    }
  ]

  const sendForm = async () => {
    if (!formValidation()) return false
    let _region = otherCountry ? otherCountryInput : region.label
    let response = await ResourceService.create(name, email, phone, otherContacts, _region, city, resource, delivery, message) 
    if(response.status === 201) {
      console.log('Has been created')
      setStatusMessage('Ваше повідомлення було відправлене.')
      setTimeout(() => {
        window.location.reload()
      }, 1000);
    }
  }

  const formValidation = () => {
    let status = true
    if (name === '') {
      setNameError(t('form_error', 'default'))
      status = false
    }
    if (email === '') {
      setEmailError(t('form_error', 'default'))
      status = false
    }
    if (!email.match("^([a-z0-9_-]+\\.)*[a-z0-9_-]+@[a-z0-9_-]+(\\.[a-z0-9_-]+)*\\.[a-z]{2,6}$")) {
      setEmailError(t('form_error', 'email'))
      status = false
    }
    if (phone === '') {
      setPhoneError(t('form_error', 'default'))
      status = false
    }
    if (!phone.match("^\\+\\d+$")) {
      setPhoneError(t('form_error', 'phone'))
      status = false
    }

    if (otherContacts !== '' && otherContacts.length > 500) {
      setOtherContactsError(t('form_error', 'message'))
      status = false
    }



    if (region === '') {
      setRegionError(t('form_error', 'default'))
      status = false
    }

    if (delivery === '') {
      setDeliveryError(t('form_error', 'default'))
      status = false
    }


    if (city === '') {
      setCityError(t('form_error', 'default'))
      status = false
    }

    if (resource === '') {
      setResourceError(t('form_error', 'default'))
      status = false
    }

    if (resource.length > 500) {
      setResourceError(t('form_error', 'message'))
      status = false
    }
    if(otherCountry) {
      if (otherCountryInput === '') {
        setOtherCountryInputError(t('form_error', 'default'))
        status = false
      }
    }
    if (message !== '' && message.length > 500) {
      setMessageError(t('form_error', 'message'))
      status = false
    }
    return status
  }


  useEffect(() => {
    setNameError('')
  }, [name])

  useEffect(() => {
    setEmailError('')
  }, [email])

  useEffect(() => {
    setPhoneError('')
  }, [phone])

  useEffect(() => {
    setOtherContactsError('')
  }, [otherContacts])

  useEffect(() => {
    setRegionError('')
  }, [region])

  useEffect(() => {
    setDeliveryError('')
  }, [delivery])

  useEffect(() => {
    setCityError('')
  }, [city])

  useEffect(() => {
    setResourceError('')
  }, [resource])

  useEffect(() => {
    setMessageError('')
  }, [message])

  useEffect(() => {
    setOtherCountryInputError('')
  }, [otherCountryInput])

  return (
    <Container>
      <Content direction='column' align='center'>
        <Title>{t('resources_form', 'title')}</Title>
        <Form>
          <Input label={t('resources_form', 'name')} error={nameError} value={name} onChangeText={setName} />
          <Input label={t('resources_form', 'email')} type='email' inputMode='email' error={emailError} value={email} onChangeText={setEmail} />
          <Input label={t('resources_form', 'phone')} description={t('resources_form', 'phone_description')} type='tel' inputMode='tel' error={phoneError} value={phone} onChangeText={setPhone} />
          <Input area label={t('resources_form', 'other_contacts')} error={otherContactsError} value={otherContacts} onChangeText={setOtherContacts} />
          <Selected>
            <SelectedTitle>{t('resources_form', 'region')}</SelectedTitle>
            <Select onChange={item => setRegion(item)} options={options} />
            {regionError !== '' && <StyledError>{regionError}</StyledError>}
          </Selected>
          {
            otherCountry
            &&
            <Input label={t('resources_form', 'other_country')} error={otherCountryInputError} value={otherCountryInput} onChangeText={setOtherCountryInput} />
          }
          <Input label={t('resources_form', 'city')} error={cityError} onChangeText={setCity} />
          <Input area label={t('resources_form', 'resource')} error={resourceError} onChangeText={setResource} />
          <Radioded error={deliveryError} type='radio' label={t('resources_form', 'delivery')} _options={deliveryOptions} getValue={setDelivery} />
          <Input area label={t('resources_form', 'message')} error={messageError} value={message} onChangeText={setMessage} />
          <SubmitButton onClick={async () => await sendForm()}>{t('buttons', 'send')}</SubmitButton>
          {statusMessage !== '' && <StyledMessage>{statusMessage}</StyledMessage>}
        </Form>
      </Content>
    </Container>
  )
})

const Title = styled.h1`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 40px 0;
  margin-bottom: 40px;
  background-color: #0A5CB1;
  color: #fff;
  border-bottom: 1px solid #efefef;
`

const Form = styled.div`
  width: 50%;
  @media (max-width: 768px) {
    width: calc(100% + (50 - 100) * ((100vw - 320px) / (1280 - 320)));
  }
`
const StyledMessage = styled.span`
  display: block;
  margin-top: 20px;
  align-self: center;
  text-align: center;
  color: #48d148;
  font-weight: 600;
`

const StyledError = styled.span`
  color: crimson;
  display: block;
  margin-top: 10px;
  font-size: 14px;
  font-weight: 500;
`

const Selected = styled.div`
  width: 100%;
  margin: 10px 0;
`

const SelectedTitle = styled.span`
  display: block;
  font-size: 14px;
  font-weight: 600;
  color: #272727;
  margin-bottom: 10px;
`

const SubmitButton = styled.span`
  width: 100%;
  height: 50px;
  background-color: #0A5CB1;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 18px;
  color: #fff;
  margin-top: 10px;
  cursor: pointer;
  transition: .5s;
  :hover {
    box-shadow: 0px 4px 10px rgba(10, 92, 177, 0.5);
    transition: .5s;
  }
`