import axios from "axios"

const baseURL = `https://api.vidbudovaua.com/`

export const api = axios.create({
  withCredentials: true,
  baseURL: baseURL,
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': process.env.REACT_APP_API_KEY,
  }
})