import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { t } from '../../utils/Intl'
import brush from '../../assets/brush.png'

import roads from '../../assets/back-images/road.jpg'
import house from '../../assets/back-images/house.jpg'
import aeroport from '../../assets/back-images/aeroport.jpg'
import activ from '../../assets/back-images/activ.jpg'
import railway from '../../assets/back-images/railway.jpg'
import health from '../../assets/back-images/health.jpg'
import bridge from '../../assets/back-images/bridge.jpg'
import school from '../../assets/back-images/school.jpg'
import port from '../../assets/back-images/port.jpg'
import military from '../../assets/back-images/military.jpg'
import admin from '../../assets/back-images/admin.jpg'
import mriya from '../../assets/back-images/mriya.jpg'
import relig from '../../assets/back-images/relig.jpg'
import det from '../../assets/back-images/det.jpg'
import trc from '../../assets/back-images/trc.jpg'
import culture from '../../assets/back-images/culture.jpg'
import nafto from '../../assets/back-images/tec.jpg'
import other from '../../assets/back-images/other.jpg'

export const Table = observer(() => {

  const data = [
    {
      title: t('table_stat', 'roads'),
      value: '23.9',
      cash: '$30 034',
      image: roads
    },
    {
      title: t('table_stat', 'buildings'),
      value: '44.8',
      cash: '$39 379',
      image: house
    },
    {
      title: t('table_stat', 'airports'),
      value: '11',
      cash: '$6 817',
      image: aeroport
    },
    {
      title: t('table_stat', 'assets'),
      value: '256',
      cash: '$11 499',
      image: activ
    },
    {
      title: t('table_stat', 'railway'),
      value: 'н/д',
      cash: '$2 672',
      image: railway
    },
    {
      title: t('table_stat', 'health'),
      value: '656',
      cash: '$1 147',
      image: health
    },
    {
      title: t('table_stat', 'bridges'),
      value: '304',
      cash: '$1 692',
      image: bridge
    },
    {
      title: t('table_stat', 'education'),
      value: '1 177',
      cash: '$1 621',
      image: school
    },
    {
      title: t('table_stat', 'gates'),
      value: '4',
      cash: '$471',
      image: port
    },
    {
      title: t('table_stat', 'military'),
      value: '12',
      cash: '$468',
      image: military
    },
    {
      title: t('table_stat', 'admin'),
      value: '111',
      cash: '$607',
      image: admin
    },
    {
      title: t('table_stat', 'plane'),
      value: '1',
      cash: '$300',
      image: mriya
    },
    {
      title: t('table_stat', 'religious'),
      value: '141',
      cash: '$81',
      image: relig
    },
    {
      title: t('table_stat', 'kindergartens'),
      value: '668',
      cash: '$576',
      image: det
    },
    {
      title: t('table_stat', 'trc'),
      value: '20',
      cash: '$345',
      image: trc
    },
    {
      title: t('table_stat', 'cultural'),
      value: '203',
      cash: '$270',
      image: culture
    },
    {
      title: t('table_stat', 'nafto'),
      value: '28',
      cash: '$227',
      image: nafto
    },
    {
      title: t('table_stat', 'other'),
      value: '-',
      cash: '$1 641',
      image: other
    }
  ]

  return (
    <Blocks>
      {data.map((item) => {
        return(
          <StyledBlock img={item.image}>
            <Back>
              <TitleSpan>{item.title}</TitleSpan>
              <ValueSpan>{item.value}</ValueSpan>
              <BrushBack>
                <CashSpan>{item.cash}<Reduction>{t('reduction', 'million')}</Reduction></CashSpan>
              </BrushBack>
            </Back>
          </StyledBlock>
        )
      })}
    </Blocks>
  )
})

const Blocks = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  grid-row-gap: 40px;
  grid-column-gap: 40px;
  @media (max-width: 768px) {
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }
`

const Reduction = styled.span`
  font-size: 12px;
  font-weight: 700;
  margin-left: 2px;
  color: crimson;
`

const StyledBlock = styled.div`
  width: 180px;
  height: 180px;
  border-radius: 4px;
  background-image: url(${({ img }) => img});
  background-size: cover;
  @media (max-width: 768px) {
    width: 150px;
    height: 150px;
  }
`

const Back = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: rgba(0,0,0,.4);
  box-shadow: 0px 0px 10px rgba(0,0,0,.025);
`

const BrushBack = styled.div`
  background-image: url(${brush});
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 50%;
  @media (max-width: 768px) {
    width: 60%;
  }
`

const TitleSpan = styled.span`
  font-weight: 700;
  font-size: 14px;
  color: #fff;
  margin-bottom: 10px;
  width: 90%;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 12px;
  }
`

const ValueSpan = styled.span`
  font-weight: 500;
  font-size: 18px;
  color: #fff;
  margin-bottom: 10px;
  width: 100%;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 18px;
  }
`

const CashSpan = styled.span`
  color: #272727;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 12px;
  }
`