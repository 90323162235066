import { api } from ".";

export class VolunteerService {

  static async get(number) {
    const query = await api.get(`volunteers/number/${number}`)
    if(query.status === 200)
        return query.data.result
    return false
  }
}