import { Outlet } from 'react-router-dom'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { Footer } from './components/Footer'

export const Layout = observer(() => {
  return (
    <Wrapper>
      <Outlet />
      <Footer />
    </Wrapper>
  )
})

const Wrapper = styled.div`
  width: 100%;
  background-color: #fff;
`