import { observer } from 'mobx-react-lite'
import { Container, Content, ContentDescription, ContentHeader, ContentHeaderLeft, ContentInner, ContentTitle } from '../../utils/styles'
import React, { useEffect, useState } from 'react'
import { t } from '../../utils/Intl'
import styled from 'styled-components'
import gif from '../../assets/have-questions.gif'
import { Form } from './Form'

export const Contact = observer(({}) => {
  return (
    <Container>
      <Content justify='space-between'>
        <ContentHeader id='contact'>
          <ContentHeaderLeft>
            <ContentTitle>{t('contact_form', 'title')}</ContentTitle>
          </ContentHeaderLeft>
        </ContentHeader>
        <ContentInner justify='space-between'>
          <Form />
          <Sticker src={gif} />
        </ContentInner>
      </Content>
    </Container>
  )
})

const Sticker = styled.img`
  width: calc(270px + (450 - 270) * ((100vw - 320px) / (1280 - 320)));
`

