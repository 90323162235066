import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Language } from './Language'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { t } from '../utils/Intl'
import { observer } from 'mobx-react-lite'
import { Logo } from './Logo'

export const Header = observer(({ }) => {

  const [open, setOpen] = useState(false)

  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  
  const handleResize = () => {
    setWindowWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return (
      window.removeEventListener('resize', handleResize)
    )
  }, [])

  const handleOpenMenu = useCallback(() => {
    if(window.innerWidth <= 768) {
      setOpen(old => !old)
      if(open) {
        document.body.style.overflow = 'unset'
      } else {
        document.body.style.overflow = 'hidden'
      }
    }
    console.log(open)
  }, [open])

  return (
    <Container open={open}>
      <Logo openMenu={open} />
      <NavBar open={open}>
        <li><a href="#help" onClick={handleOpenMenu}>{t('header_menu', 'help')}</a></li>
        <li><a href="#problem" onClick={handleOpenMenu}>{t('header_menu', 'problem')}</a></li>
        <li><a href="#mission" onClick={handleOpenMenu}>{t('header_menu', 'mission')}</a></li>
        <li><a href="#donate" onClick={handleOpenMenu}>{t('header_menu', 'donate')}</a></li>
        <li><a href="#contact" onClick={handleOpenMenu}>{t('header_menu', 'contact')}</a></li>
        <Language />
      </NavBar>
      <Burger 
        open={open} 
        onClick={handleOpenMenu}
        onClickCapture
      >
        <span></span>
      </Burger>
    </Container>
  )
})


const Container = styled.header`
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
  justify-content: space-between;
  @media (max-width: 768px) {
    height: 80px;
    ::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 80px;
      display: flex;
      align-self: center;
      z-index: 3;
      transition: all .3s ease 0s;
    };
  };
`

const NavBar = styled.ul`
  display: inline-flex;
  z-index: 2;
  li {
    margin-right: 30px;
  };
  li > a {
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    transition: .5s;
    :hover {
      color: var(--secondary);
      transition: .5s;
    }
  };
  @media (max-width: 768px) {
    position: fixed;
    top: 0;
    right: ${({ open }) => open ? 0 : '-100%'};
    width: 100%;
    height: 100%;
    padding-bottom: 80px;
    background: #FFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 80px;
    transition: all .3s ease 0s;
    li {
      margin-bottom: 20px;
      margin-left: 20px;
    }
    li > a {
      color: #272727;
      font-size: 28px;
    };
  };
`

const Burger = styled.div`
  display: none;
  z-index: 3;
  @media (max-width: 768px) {
    position: relative;
    display: block;
    width: 30px;
    height: 20px;
    span {
      position: absolute;
      background-color: ${({ open }) => open ? '#101010' : '#FFF'};
      left: 0;
      width: 100%;
      height: 2px;
      top: 9px;
      transform: ${({ open }) => open ? 'scale(0)' : 'scale(1)'};
      transition: all .3s ease 0s;
    }
    ::before, ::after {
      content: '';
      background-color: ${({ open }) => open ? '#101010' : '#FFF'};
      position: absolute;
      width: 100%;
      height: 2px;
      left: 0;
      transition: all .3s ease 0s;
    };
    ::before {
      top: ${({ open }) => open ? '9px' : 0};
      transform: ${({ open }) => open ? 'rotate(45deg)' : 'rotate(0deg)'};
    };
    ::after {
      bottom: ${({ open }) => open ? '9px' : 0};
      transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0deg)'};
    }
  };
`
