import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { t } from '../../utils/Intl'

export const Requisites = observer(({ }) => {

  const [currentTab, setCurrentTab] = useState(0)

  const copyToClipBoard = (text) => {
    navigator.clipboard.writeText(text)
  }

  return (
    <Container>
      <Title>{t('requisites', 'title')}</Title>
      <Tabs>
        <TabsNav>
          <TabsItem onClick={() => setCurrentTab(0)} currentTab={currentTab === 0 && true}>{t('requisites', 'grivna')} ₴</TabsItem>
          <TabsItem onClick={() => setCurrentTab(1)} currentTab={currentTab === 1 && true}>{t('requisites', 'dollar')} $</TabsItem>
          <TabsItem onClick={() => setCurrentTab(2)} currentTab={currentTab === 2 && true}>{t('requisites', 'euro')} €</TabsItem>
        </TabsNav>
        <TabsBody>
          {
            currentTab === 0
            ?
            <TabsBlock>
              <BlockItem>
                <BlockTitle>{t('requisites', 'name_title')}</BlockTitle>
                <BlockValue>{t('requisites', 'name_value')}</BlockValue>
                <button onClick={() => copyToClipBoard(t('requisites', 'name_value'))}>
                  <i class='bx bx-copy' ></i>
                </button>
              </BlockItem>
              <BlockItem>
                <BlockTitle>{t('requisites', 'address_title')}</BlockTitle>
                <BlockValue>{t('requisites', 'address_value')}</BlockValue>
                <button onClick={() => copyToClipBoard(t('requisites', 'address_value'))}>
                  <i class='bx bx-copy' ></i>
                </button>
              </BlockItem>
              <BlockItem>
                <BlockTitle>IBAN:</BlockTitle>
                <BlockValue>UA233052990000026000026412017</BlockValue>
                <button onClick={() => copyToClipBoard("UA233052990000026000026412017")}>
                  <i class='bx bx-copy' ></i>
                </button>
              </BlockItem>
              <BlockItem>
                <BlockTitle>{t('requisites', 'code_title')}</BlockTitle>
                <BlockValue>44850579</BlockValue>
                <button onClick={() => copyToClipBoard("44850579")}>
                  <i class='bx bx-copy' ></i>
                </button>
              </BlockItem>
              <BlockItem>
                <BlockTitle>{t('requisites', 'beneficiary_bank_title')}</BlockTitle>
                <BlockValue>{t('requisites', 'beneficiary_bank_value')}</BlockValue>
                <button onClick={() => copyToClipBoard(t('requisites', 'beneficiary_bank_value'))}>
                  <i class='bx bx-copy' ></i>
                </button>
              </BlockItem>
              <BlockItem>
                <BlockTitle>{t('requisites', 'recipient')}</BlockTitle>
                <BlockValue>{t('requisites', 'name_value')}</BlockValue>
                <button onClick={() => copyToClipBoard(t('requisites', 'name_value'))}>
                  <i class='bx bx-copy' ></i>
                </button>
              </BlockItem>
            </TabsBlock>
            :
            currentTab === 1
            ?
            <TabsBlock>
                  <BlockItem>
                    <BlockTitle>{t('requisites', 'name_title')}</BlockTitle>
                    <BlockValue>{t('requisites', 'name_value')}</BlockValue>
                    <button onClick={() => copyToClipBoard(t('requisites', 'name_value'))}>
                      <i class='bx bx-copy' ></i>
                    </button>
                  </BlockItem>
                  <BlockItem>
                    <BlockTitle>{t('requisites', 'address_title')}</BlockTitle>
                    <BlockValue>{t('requisites', 'address_value')}</BlockValue>
                    <button onClick={() => copyToClipBoard(t('requisites', 'address_value'))}>
                      <i class='bx bx-copy' ></i>
                    </button>
                  </BlockItem>
                  <BlockItem>
                    <BlockTitle>IBAN:</BlockTitle>
                    <BlockValue>UA483052990000026009016406584</BlockValue>
                    <button onClick={() => copyToClipBoard("UA483052990000026009016406584")}>
                      <i class='bx bx-copy' ></i>
                    </button>
                  </BlockItem>


                  <BlockItem>
                    <BlockTitle>{t('requisites', 'beneficiary_bank_title')}</BlockTitle>
                    <BlockValue>{t('requisites', 'beneficiary_bank_value')}</BlockValue>
                    <button onClick={() => copyToClipBoard(t('requisites', 'beneficiary_bank_value'))}>
                      <i class='bx bx-copy' ></i>
                    </button>
                  </BlockItem>
                  <BlockItem>
                    <BlockTitle>SWIFT:</BlockTitle>
                    <BlockValue>PBANUA2X</BlockValue>
                    <button onClick={() => copyToClipBoard("PBANUA2X")}>
                      <i class='bx bx-copy' ></i>
                    </button>
                  </BlockItem>

                  
            </TabsBlock>
            :
            <TabsBlock>
                  <BlockItem>
                    <BlockTitle>{t('requisites', 'name_title')}</BlockTitle>
                    <BlockValue>{t('requisites', 'name_value')}</BlockValue>
                    <button onClick={() => copyToClipBoard(t('requisites', 'name_value'))}>
                      <i class='bx bx-copy' ></i>
                    </button>
                  </BlockItem>
                  <BlockItem>
                    <BlockTitle>{t('requisites', 'address_title')}</BlockTitle>
                    <BlockValue>{t('requisites', 'address_value')}</BlockValue>
                    <button onClick={() => copyToClipBoard(t('requisites', 'address_value'))}>
                      <i class='bx bx-copy' ></i>
                    </button>
                  </BlockItem>
                  <BlockItem>
                    <BlockTitle>IBAN:</BlockTitle>
                    <BlockValue>UA493052990000026005036405774</BlockValue>
                    <button onClick={() => copyToClipBoard("UA493052990000026005036405774")}>
                      <i class='bx bx-copy' ></i>
                    </button>
                  </BlockItem>
                  <BlockItem>
                    <BlockTitle>{t('requisites', 'beneficiary_bank_title')}</BlockTitle>
                    <BlockValue>{t('requisites', 'beneficiary_bank_value')}</BlockValue>
                    <button onClick={() => copyToClipBoard(t('requisites', 'beneficiary_bank_value'))}>
                      <i class='bx bx-copy' ></i>
                    </button>
                  </BlockItem>
                  <BlockItem>
                    <BlockTitle>SWIFT:</BlockTitle>
                    <BlockValue>PBANUA2X</BlockValue>
                    <button onClick={() => copyToClipBoard("PBANUA2X")}>
                      <i class='bx bx-copy' ></i>
                    </button>
                  </BlockItem>
            </TabsBlock>
          }
        </TabsBody>
      </Tabs>
    </Container>
  )
})

const Container = styled.div`
  flex: 1.5;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
`

const Tabs = styled.div`
  margin-top: 20px;
`

const TabsNav = styled.nav`
  display: flex;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  flex-wrap: wrap;
`

const TabsItem = styled.button`
  flex: 0 1 33.333%;
  height: 50px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, .05);
  border-radius: 6px;
  background: none;
  background-color: ${({ currentTab }) => currentTab ? 'var(--primary)' : '#FFF'};
  font-size: 18px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ currentTab }) => currentTab ? '#FFF' : 'var(--primary)'};;
  text-decoration: none;
  border: none;
  outline: none;
  cursor: pointer;
  transition: .4s;
`

const TabsBody = styled.div`
  width: 100%;
  min-height: 200px;
  margin-top: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, .05);
  background-color: #FFF;
  border-radius: 12px;
  padding: 15px;
`

const TabsBlock = styled.div`
  display: flex;
  flex-direction: column;
`

const BlockItem = styled.div`
  margin-bottom: 10px;
  button {
    border: none;
    outline: none;
    padding: 0;
    background: none;
    i {
      color: #B0B0B0;
      margin-left: 10px;
      font-size: 18px;
      cursor: pointer;
      transition: .4s;
      :hover {
        color: var(--secondary);
        transition: .4s;
      }
    }
  };
`

const BlockTitle = styled.span`
  font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1280 - 320)));
  font-weight: 600;
`

const BlockValue = styled.span`
  font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1280 - 320)));
  margin-left: 10px;
  font-weight: 500;
`

const Title = styled.span`
  font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1280 - 320)));
  font-weight: 700;
  margin-bottom: 10px;
`