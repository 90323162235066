import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import sticker from '../../assets/stickers/sticker_hello.webp'
import technics from '../../assets/goals/technics.png'
import materials from '../../assets/goals/materials.png'
import tools from '../../assets/goals/tools.png'

import { t } from '../../utils/Intl'
import { Container, Content, ContentHeader, ContentTitle, ContentDescription, ContentInner, ContentHeaderLeft, ContentHeaderSticker, ContentInnerTitle } from '../../utils/styles'
import { Button } from '../ui/Button'

export const Problem = observer(({ }) => {

  return (
    <Container>
      <Content>
        <ContentInner direction='column' align='flex-start'>
            <Div id='problem'>
                <ContentInnerTitle>{t('problem', 'title')}</ContentInnerTitle>
                <List>
                    <li><span>{t('problem', '1')}</span></li>
                    <li><span>{t('problem', '2')}</span></li>
                    <li><span>{t('problem', '3')}</span></li>
                    <li><span>{t('problem', '4')}</span></li>
                    <li><span>{t('problem', '5')}</span></li>
                </List>
            </Div>
            <Div>
                <ContentInnerTitle>{t('decision', 'title')}</ContentInnerTitle>
                <Items>
                    <li><b>{t('decision', 'title_1')}</b><span>{t('decision', '1')}</span></li>
                    <li><b>{t('decision', 'title_2')}</b><span>{t('decision', '2')}</span></li>
                    <li><b>{t('decision', 'title_3')}</b><span>{t('decision', '3')}</span></li>
                    <li><b>{t('decision', 'title_4')}</b><span>{t('decision', '4')}</span></li>
                    <li><b>{t('decision', 'title_5')}</b><span>{t('decision', '5')}</span></li>
                    <li><b>{t('decision', 'title_6')}</b><span>{t('decision', '6')}</span></li>
                </Items>
            </Div>
            <Div id='mission'>
                <ContentInnerTitle>{t('mission', 'title')}</ContentInnerTitle>
                <ContentDescription>{t('mission', 'desc')}</ContentDescription>
            </Div>
            <Div>
                <ContentInnerTitle>{t('goal', 'title')}</ContentInnerTitle>
                <ContentDescription>{t('goal', 'desc')}</ContentDescription>
            </Div>
        </ContentInner>
      </Content>
    </Container>
  )
})


const Div = styled.div`
    margin-bottom: 20px;
`

const List = styled.ul`
    margin: 20px 0;
    li {
        margin-bottom: 10px;
        margin-left: 20px;
        list-style: disc;
        span {
            font-size: 18px;
            font-weight: 500;
            color: #272727;

        }
    }
`

const Items = styled.ul`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    margin: 20px 0;
    li {
        background: #FAFAFA;
        box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.05);
        padding: 10px;
        width: 250px;
        border-radius: 8px;
        height: 200px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        grid-row-gap: 15px;
        b {
            text-align: center;
            font-size: 22px;
        };
        span {
            text-align: center;
            font-size: 16px;
            font-weight: 500;
            color: #272727;
        };
    };
`