import { observer } from 'mobx-react-lite'
import React from 'react'
import styled from 'styled-components'

import ua_logo_dark from '../assets/logo/dark/ua_logo.svg'
import en_logo_dark from '../assets/logo/dark/en_logo.svg'
import de_logo_dark from '../assets/logo/dark/de_logo.svg'

import ua_logo_light from '../assets/logo/light/ua_logo.svg'
import en_logo_light from '../assets/logo/light/en_logo.svg'
import de_logo_light from '../assets/logo/light/de_logo.svg'

import IntlStore from '../store/Intl.store'

export const Logo = observer(({ openMenu }) => {

  const getLogo = () => {
    switch(openMenu) {
      case false: 
        switch (IntlStore.lang) {
          case 'ua': return ua_logo_light
          case 'en': return en_logo_light
          case 'de': return de_logo_light
          default: return ua_logo_light
        }
      case true:
        switch (IntlStore.lang) {
          case 'ua': return ua_logo_dark
          case 'en': return en_logo_dark
          case 'de': return de_logo_dark
          default: return ua_logo_dark
        }
      default:
        switch (IntlStore.lang) {
          case 'ua': return ua_logo_light
          case 'en': return en_logo_light
          case 'de': return de_logo_light
          default: return ua_logo_light
        }
    }
  }

  return (
    <Link href='/'>
      <StyledLogo src={getLogo()} />
    </Link>
  )
})

const Link = styled.a`

`

const StyledLogo = styled.img`
  width: 140px;
  z-index: 10;
  transition: all .3s ease 0s;
  @media (max-width: 1280px) {
    width: calc(100px + (140 - 100) * ((100vw - 320px) / (1280 - 320)));
  };
`
