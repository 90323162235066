import { observer } from 'mobx-react-lite'
import React from 'react'
import styled from 'styled-components'
import { t } from '../../utils/Intl'
import { Container, Content, ContentHeader, ContentHeaderLeft, ContentInner, ContentTitle, Wrapper } from '../../utils/styles'
import { DonateMenu } from './DonateMenu'

export const Donates = observer(({ }) => {

  return (
    <Container>
      <Content>
        <ContentHeader id='donate'>
          <ContentHeaderLeft>
            <ContentTitle>{t('donate', 'title')}</ContentTitle>
          </ContentHeaderLeft>
        </ContentHeader>
        <ContentInner align='flex-start' justify='flex-start'>
        </ContentInner>
      </Content>
    </Container>
  )
})