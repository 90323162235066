import { observer } from 'mobx-react-lite'
import React, { useState, useCallback } from 'react'
import styled from 'styled-components'

export const Input = observer(({ isLabel = true, area = false, label, type = 'text', value, onChangeText, description, error, required = false, pattern, autoCapitalize = 'sentences', inputMode = 'default' }) => {

  const [valueInput, setValueInput] = useState('')
  const [isFocus, setIsFocus] = useState(false)

  const handleChangeText = useCallback((text) => {
    setValueInput(text)
    onChangeText && onChangeText(text)
  })

  const handleFocus = useCallback((state) => {
    setIsFocus(state)
  })

  const handlePlaceHolder = useCallback(() => {
    if (isFocus && (valueInput === '' || value === '')) return ''
    return label
  })

  return (
    <StyledField active={isFocus} isText={valueInput === '' ? false : true}>
      <label>{label}</label>
      {
        area
        ?
        
        <AreaField
          active={isFocus}
          inputMode={inputMode}
          autoCapitalize={autoCapitalize}
          value={value || valueInput}
          onChange={text => handleChangeText(text.target.value)}
          onFocus={handleFocus}
          onBlur={() => handleFocus(false)}
          required={required}
        />
        :
        <InputField
          pattern={pattern}
          active={isFocus}
          type={type}
          inputMode={inputMode}
          autoCapitalize={autoCapitalize}
          value={value || valueInput}
          onChange={text => handleChangeText(text.target.value)}
          onFocus={handleFocus}
          onBlur={() => handleFocus(false)}
          required={required}
        />
      }
      {description && <Description>{description}</Description>}
      {error && <StyledError>{error}</StyledError>}
    </StyledField>
  )
})

const StyledField = styled.div`
  width: 100%;
  min-height: 60px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  label {
    display: block;
    position: absolute;
    width: max-content;
    color: #272727;
    font-size: ${({ active, isText }) => active || isText ? '14px' : '18px'};
    font-weight: ${({ active, isText }) => active || isText ? 600 : 400};
    transform: ${({ active, isText }) => active || isText ? 'translate(0px, 0px)' : 'translate(0px, 23px)'};
    pointer-events: none;
    transition: .4s;
  };
`

const Description = styled.span`
  color: #b0b0b0;
  font-size: 14px;
  font-weight: 500;
  margin: 5px 0;
`

const StyledError = styled.span`
  color: crimson;
  font-size: 14px;
  font-weight: 500;
`

const InputField = styled.input`
  border: none;
  border-bottom: ${({ active }) => active ? '1px solid #0a5cb1' : '1px solid #efefef'};
  width: 100%;
  padding: 15px 10px;
  margin: 10px 0;
  outline: none;
  font-size: 18px;
  font-weight: 500;
  font-family: sans-serif;
  transition: .4s;
`

const AreaField = styled.textarea`
  border: none;
  border-bottom: ${({ active }) => active ? '1px solid #0a5cb1' : '1px solid #efefef'};
  width: 100%;
  padding: 15px 10px;
  margin: 10px 0;
  outline: none;
  font-size: 18px;
  font-weight: 500;
  font-family: sans-serif;
  resize: vertical;
  transition: .4s;
`

