import uaLang from '../languages/ua.json'
import enLang from '../languages/en.json'
import deLang from '../languages/de.json'
import IntlStore from '../store/Intl.store'

export const t = (section, value) => {
  const lang = IntlStore.lang
  
  switch (lang) {
    case 'uk' || 'ru':
      return uaLang[section][value]
    case 'en':
      return enLang[section][value]
    case 'de':
      return deLang[section][value]
    default:
      return uaLang[section][value]
  }
}