import { Routes, Route } from 'react-router-dom'
import { Layout } from './layout'
import { HavePage } from './pages/Have'
import { HomePage } from './pages/Home'
import { VolunteerPage } from './pages/Volunteer'
import Offer from './pages/Offer'

export const useRoutes = () => {
  return (
    <>
      <Routes>
        <Route path='/' element={<Layout />} >
          <Route index element={<HomePage />} />
          <Route path='/have' element={<HavePage />} />
          <Route path='/volunteers/:id' element={<VolunteerPage />} />
          <Route path='/offer' element={<Offer />} />
          <Route path='*' element={<h1>404</h1>} />
        </Route>
      </Routes>
    </>
  )
}