import { observer } from 'mobx-react-lite'
import React from 'react'
import styled from 'styled-components'
import { t } from '../utils/Intl'
import { Container, Content } from '../utils/styles'
import { Logo } from './Logo'

export const Footer = observer(({}) => {

  var pathToFile = "../assets/vidbudovaua.pdf"
  const downloadFile = () => {
    window.location.href = pathToFile
  }


  return (
    <Container bcolor='#101010'>
      <Content>
        <Nav>
          <LogoItem>
            <Logo />
            <span>{t('footer', 'description')}</span>
          </LogoItem>
          <NavItem>
            <span>{t('footer_titles', 'main')}</span>
            <li><a href="/#help">{t('footer_buttons', 'help')}</a></li>
            <li><a href="/#donate">{t('footer_buttons', 'donate')}</a></li>
            <li><a href="/offer">{t('footer_buttons', 'offert')}</a></li>
          </NavItem>
          <NavItem>
            <span>{t('footer_titles', 'concacts')}</span>
            <li><a href="/#contact" >{t('footer_buttons', 'contact')}</a></li>
            <li>4.5.0@vidbudovaua.com</li>
          </NavItem>
          <NavItem>
            <span>{t('footer_titles', 'social_networks')}</span>
            <li><a href="https://www.facebook.com/vidbudova" target='_blank' >Facebook</a></li>
          </NavItem>
        </Nav>
      </Content>
    </Container>
  )
})

const Nav = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`

const LogoItem = styled.div`
  margin-bottom: 40px;
  margin-right: 80px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  span {
    color: #b0b0b0;
    font-size: 12px;
    margin-top: 20px;
  }
`


const NavItem = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 80px;
  span {
    color: #FFF;
    font-weight: 600;
    margin-bottom: 15px;
    font-size: 18px;
  };
  li {
    color: #FFF;
    font-size: 14px;
    margin-bottom: 10px;
  };
  li > a {
    color: #FFF;
    transition: .5s;
    :hover {
      color: var(--secondary);
      transition: .5s;
    };
  };
  @media (max-width: 768px) {
    margin-bottom: 40px;
  }
`