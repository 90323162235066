import React, { useState, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { Container, Content } from '../utils/styles'
import { observer } from 'mobx-react-lite'
import { t } from '../utils/Intl'
import { useParams } from 'react-router-dom'
import { VolunteerService } from '../services/Volunteer.service'

export const VolunteerPage = observer(({ }) => {

    const { id } = useParams()
    const [volunteer, setVolunteer] = useState({})

    useEffect(() => {
        getVolunteerInfo()
    }, [])

    const getVolunteerInfo = async () => {
        try {
            let res = await VolunteerService.get(id)
            if(res !== false)
            setVolunteer(res)
            console.log(res)
        } catch (error) {
            setVolunteer(null)
            console.log(error)
        }
    }

    return (
        <Container>
        <Content direction='column' align='center'>
            {/* <Title>{t('volunteer', 'title')}</Title> */}
            <Title>Page under construction</Title>
            {/* {
                volunteer !== null
                ?
                <ContainerBody>
                    <Form>
                        <InputContainer>
                            <Label>{t('volunteer', 'number')}</Label>
                            <Text>{volunteer.number}</Text>
                            <TextDescription>
                                {t('volunteer', 'number_desc')}
                            </TextDescription>
                        </InputContainer>
                        <InputContainer>
                            <Label>{t('volunteer', 'surname')}</Label>
                            <Text>{volunteer.surname}</Text>
                        </InputContainer>
                        <InputContainer>
                            <Label>{t('volunteer', 'name')}</Label>
                            <Text>{volunteer.name}</Text>
                        </InputContainer>
                        <InputContainer>
                            <Label>{t('volunteer', 'parname')}</Label>
                            <Text>{volunteer.parname}</Text>
                        </InputContainer>
                        <InputContainer>
                            <Label>{t('volunteer', 'birthday')}</Label>
                            <Text>{new Date(volunteer.birthday).toLocaleDateString()}</Text>
                        </InputContainer>
                        <InputContainer>
                            <Label>{t('volunteer', 'citizenship')}</Label>
                            <Text>{volunteer.citizenship}</Text>
                        </InputContainer>
                        <InputContainer>
                            <Label>{t('volunteer', 'issuedate')}</Label>
                            <Text>{new Date(volunteer.issuedate).toLocaleDateString()}</Text>
                            <TextDescription>
                                {t('volunteer', 'issuedate_desc')}
                            </TextDescription>
                        </InputContainer>
                        <InputContainer>
                            <Label>{t('volunteer', 'validuntildate')}</Label>
                            <Text>{new Date(volunteer.validuntildate).toLocaleDateString()}</Text>
                            <TextDescription>
                                {t('volunteer', 'validuntildate_desc')}
                            </TextDescription>
                        </InputContainer>
                    </Form>

                    <PhotoContainer>
                        <Label>Фото волонтера</Label>
                        <Photo photo={volunteer.imageurl} />
                    </PhotoContainer>
                </ContainerBody>
                :
                <ErrorBlock>
                    <h3>{t('volunteer', 'error')}</h3>
                </ErrorBlock>
            } */}
        </Content>
        </Container>
    )
})

const Title = styled.h1`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 40px 0;
  margin-bottom: 40px;
  background-color: #0A5CB1;
  color: #fff;
  border-bottom: 1px solid #efefef;
`

const ContainerBody = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap-reverse;
    padding: 20px 0;
    grid-column-gap: 20px;
`

const Form = styled.div`
    display: flex;
    flex-direction: column;
    grid-row-gap: 20px;
`

const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    grid-row-gap: 10px;
`

const Label = styled.label`
    font-size: 16px;
    font-weight: 700;
    color: '#272727';
`

const Text = styled.label`
    font-size: 18px;
    font-weight: 500;
    color: '#272727';
`

const TextDescription = styled.span`
    color: #B0B0B0;
    font-size: 14px;
    font-weight: 500;
`

const Photo = styled.div`
    height: 400px;
    width: 300px;
    border: 1px solid #EFEFEF;
    background: none;
    background-image: url(${({ photo }) => photo});
    background-size: cover;
`

const PhotoContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    grid-row-gap: 10px;
    margin-bottom: 20px;
`

const ErrorBlock = styled.div`
    height: 100vh;
    h3 {
        text-align: center;
    }
`