import { observer } from 'mobx-react-lite'
import React, { useState, useCallback } from 'react'
import styled from 'styled-components'

export const Radioded = observer(({ label, error, _options, getValue, description}) => {
  const initial = [
    {
      name: 'default',
      value: 'def',
      label: 'Default'
    },
    {
      name: 'default',
      value: 'def',
      label: 'Default'
    }
  ]
  const [options, setOptions] = useState(_options ? _options : initial)
  
  const handleClick = (value) => {
    getValue(value)
  }
  
  return (
    <Container>
      <Title>{label}</Title>
      {options.map((item) => {
        return (
          <Radio name={item.name} label={item.label} value={item.value} onChange={handleClick} />
        )
      })}
      {description && <Description>{description}</Description>}
      {error && <StyledError>{error}</StyledError>}
    </Container>
  )
})

const Radio = observer(({ name, label, value, onChange }) => {
  const [selected, setSlected] = useState(false)

  return (
    <Label>
      <StyledRadio type='radio' value={value} name={name} onClick={() => onChange(label)} />
      <span>{label}</span>
    </Label>
  )
})

const Container = styled.div`
  width: 100%;
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const Description = styled.span`
  color: #b0b0b0;
  font-size: 14px;
  font-weight: 500;
  margin-top: 5px;
`

const Title = styled.span`
  color: var(--text);
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
`

const StyledError = styled.span`
  color: crimson;
  font-size: 14px;
  font-weight: 500;
`

const Label = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  span {
    display: block;
    font-size: 18px;
    font-weight: 400;
    margin-left: 10px;
    color: var(--text);
  }
`

const StyledRadio = styled.input`
  border: 1px solid #efefef;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  margin: 10px 0;
`