import React from 'react'
import styled from 'styled-components'
import google from '../../assets/Google_Pay.svg'
import apple from '../../assets/Apple_Pay.svg'
import visa from '../../assets/visa.svg'

import btc from '../../assets/crypto/bitcoin.svg'
import bnb from '../../assets/crypto/bnb.svg'
import dogecoin from '../../assets/crypto/dogecoin.svg'
import eth from '../../assets/crypto/ethereum.svg'

import mastercard from '../../assets/mastercard.svg'
import { observer } from 'mobx-react-lite'
import { t } from '../../utils/Intl'
import { DonateMenu } from '../donates/DonateMenu'

export const PaymentCard = observer(({ }) => {

  const copyToClipBoard = (text) => {
    navigator.clipboard.writeText(text)
  }

  return (
    <Container>
    <PaymentButton>
      <PaymentTitle>{t('donate', 'payment_card_title')}</PaymentTitle>
      <DonateMenu />
      <PaymentElements bg={false} >
        <PaymentVariatns>
          <img src={google} />
          <img src={apple} />
          <img src={visa} />
          <img src={mastercard} />
        </PaymentVariatns>
      </PaymentElements>
    </PaymentButton>
    <PayPal>
        <PaymentTitle style={{ color: '#FFF' }}>PayPal</PaymentTitle>
        <PaymentElements>
          <PaymentVariatns>
            <span>korytko.dmytrii@gmail.com</span>
          </PaymentVariatns>
          <button onClick={() => copyToClipBoard("korytko.dmytrii@gmail.com")}>
            <i class='bx bx-copy' ></i>
          </button>
        </PaymentElements>
    </PayPal>
    <PaymentButton href='https://vidbudovaua.pay.whitepay.com/' target='_blank' style={{marginTop: 20}}>
        <PaymentTitle>{t('donate', 'payment_crypto_title')}</PaymentTitle>
        <PaymentElements>
          <PaymentVariatns>
            <img src={btc} />
            <img src={bnb} />
            <img src={eth} />
            <img src={dogecoin} />
            <span>...</span>
          </PaymentVariatns>
          <a href="https://vidbudovaua.pay.whitepay.com/donation" target='_blank'>
            <PaymentDonate>Donate</PaymentDonate>
          </a>
        </PaymentElements>
    </PaymentButton>
    </Container>
  )
})

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: calc(100% + (100 - 100) * ((100vw - 320px) / (1280 - 320)));
`

const PaymentButton = styled.div`
  background: var(--secondary);
  flex: 1;
  width: calc(100% + (100 - 100) * ((100vw - 320px) / (1280 - 320)));
  border-radius: 8px;
  padding: calc(20px + (25 - 20) * ((100vw - 320px) / (1280 - 320)));
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  transition: .5s;
  :hover {
    box-shadow: 0px 4px 10px rgba(255, 215, 0, 0.5);
    transition: .5s;
  }
`

const PaymentTitle = styled.span`
  font-weight: 700;
  font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1280 - 320)));
  margin-bottom: calc(10px + (20 - 10) * ((100vw - 320px) / (1280 - 320)));
  color: #272727;
`

const PaymentElements = styled.div`
  background-color: ${({ bg }) => bg === false ? 'none' : '#FFF'};
  width: 100%;
  height: calc(50px + (60 - 50) * ((100vw - 320px) / (1280 - 320)));
  border-radius: 4px;
  padding: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  button {
    border: none;
    outline: none;
    padding: 0;
    background: none;
    i {
      color: #B0B0B0;
      margin-left: 10px;
      font-size: 18px;
      cursor: pointer;
      transition: .4s;
      :hover {
        color: var(--secondary);
        transition: .4s;
      }
    }
  };
`

const PaymentVariatns = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    height: calc(15px + (20 - 15) * ((100vw - 320px) / (1280 - 320)));
    margin-right: 15px;
  };
  span {
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1280 - 320)));
    font-weight: 600;
    font-family: sans-serif;
  }
`

const PaymentDonate = styled.span`
  color: #FFF;
  background-color: #0A5CB1;
  padding: calc(5px + (8 - 5) * ((100vw - 320px) / (1280 - 320))) calc(13px + (16 - 13) * ((100vw - 320px) / (1280 - 320)));
  border-radius: 4px;
  font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1280 - 320)));
`

const PayPal = styled.div`
  margin-top: 20px;
  background: var(--primary);
  flex: 1;
  width: calc(100% + (100 - 100) * ((100vw - 320px) / (1280 - 320)));
  border-radius: 8px;
  padding: calc(20px + (25 - 20) * ((100vw - 320px) / (1280 - 320)));
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`

const Crypto = styled.div`
  background: var(--secondary);
  flex: 1;
  width: calc(100% + (100 - 100) * ((100vw - 320px) / (1280 - 320)));
  border-radius: 8px;
  padding: calc(20px + (25 - 20) * ((100vw - 320px) / (1280 - 320)));
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 20px;
`