import { observer } from 'mobx-react-lite'
import React from 'react'
import styled from 'styled-components'

import { t } from '../../utils/Intl'
import { Container, Content, ContentDescription, ContentInner} from '../../utils/styles'

export const Vidbudova = observer(({ }) => {

  return (
    <Container>
      <Content>
        <ContentInner border direction='column' align='flex-start'>
          <TitleSteps>{t('vidbudova', 'what')}</TitleSteps>
          <ContentDescription>{t('vidbudova', 'what_desc')}</ContentDescription>
          <Steps>
            <Step>
              <h2>1</h2>
              <span>{t('vidbudova_steps', 'step_1')}</span>
            </Step>
            <Step>
              <h2>2</h2>
              <span>{t('vidbudova_steps', 'step_2')}</span>
            </Step>
            <Step>
              <h2>3</h2>
              <span>{t('vidbudova_steps', 'step_3')}</span>
            </Step>
            <Step>
              <h2>4</h2>
              <span>{t('vidbudova_steps', 'step_4')}</span>
            </Step>
            <Step>
              <h2>5</h2>
              <span>{t('vidbudova_steps', 'step_5')}</span>
            </Step>
          </Steps>
        </ContentInner>
      </Content>
    </Container>
  )
})


const TitleSteps = styled.span`
  font-size: calc(24px + (32 - 24) * ((100vw - 320px) / (1280 - 320)));
  font-weight: 700;
  color: var(--text);
  margin: 20px 0;
`

const Steps = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: 40px 0;
  grid-row-gap: 30px;
  grid-column-gap: 30px;
  flex: 1;
`

const Step = styled.div`
  background: #FAFAFA;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.05);
  padding: 15px;
  width: 300px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  h2 {
    color: #0A5CB1;
    margin-bottom: 20px;
    font-size: calc(42px + (56 - 42) * ((100vw - 320px) / (1280 - 320)));
  }
  span {
    font-style: italic;
    font-weight: 600;
    text-align: center;
    font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1280 - 320)));
  }
`